:root {
  --tg-color-scheme: dark;
  --tg-theme-bg-color: #212121;
  --tg-theme-text-color: #ffffff;
  --tg-theme-hint-color: #aaaaaa;
  --tg-theme-link-color: #8774e1;
  --tg-theme-button-color: #8774e1;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #0f0f0f;
  --tg-viewport-height: 576px;
  --tg-viewport-stable-height: 576px;

  --text-confirm-color: #31b545;
  --text-destructive-color: #f16d66;
}

/* enable for tApp only, disable for TVs */
@media (prefers-color-scheme: light) and (max-width: 480px) {
  :root {
    --tg-color-scheme: light;
    --tg-theme-bg-color: #ffffff;
    --tg-theme-text-color: #000000;
    --tg-theme-hint-color: #707579;
    --tg-theme-link-color: #3390ec;
    --tg-theme-button-color: #3390ec;
    --tg-theme-button-text-color: #ffffff;
    --tg-theme-secondary-bg-color: #f4f4f5;
    --tg-viewport-height: 576px;
    --tg-viewport-stable-height: 576px;

    --text-confirm-color: #31b545;
    --text-destructive-color: #f56058;

  }
}

body {
  color: var(--tg-theme-text-color);
  margin: 0;
  background-color: var(--tg-theme-bg-color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}