:root {
  --bg: #edf2f4;
  --bg-accent: var(--bg);
  --primary: #2b2d42;
  --secondary: #8d99ae;
  --sabstrate-transparent: rgba(0, 0, 0, 0.6);
}

/* :root {
  --bg: #ede7e3;
  --bg-accent: #82c0cc;
  --primary: #16697a;
  --secondary: #489fb5;
  --sabstrate-transparent: rgba(0, 0, 0, 0.6);
} */

@keyframes TopTextAppear {
  0% {
    transform: scale(2);
    color: var(--primary);
  }
  50% {
    transform: scale(2);
    color: var(--primary);
  }
  100% {
    transform: scale(1);
    color: var(--bg);
  }
}

.top-text-appear-animation {
  will-change: transform;
  animation: TopTextAppear 1.5s cubic-bezier(0.86, 0, 0.07, 1);
  transform-origin: left top;
}

@keyframes BottomTextAppear {
  0% {
    background-color: transparent;
    color: var(--secondary);
  }
  50% {
    background-color: transparent;
    color: var(--secondary);
  }
  100% {
    background-color: var(--sabstrate-transparent);
    color: var(--bg);
  }
}

.bottom-text-appear-animation {
  animation: BottomTextAppear 1.5s cubic-bezier(0.86, 0, 0.07, 1);
  transform-origin: left top;
}

@keyframes TextBackgroundAppear {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: transparent;
  }
  100% {
    background-color: var(--sabstrate-transparent);
  }
}

.text-background-appear-animation {
  animation: TextBackgroundAppear 1.5s cubic-bezier(0.86, 0, 0.07, 1);
  transform-origin: left top;
}

@keyframes ContentAppear {
  0% {
    backdrop-filter: blur(20px);
    background-color: var(--bg-accent);
  }
  50% {
    backdrop-filter: blur(20px);
    background-color: var(--bg-accent);
  }
  100% {
    background-color: transparent;
  }
}

.content-appear-animation {
  animation: ContentAppear 1.5s cubic-bezier(0.86, 0, 0.07, 1);
  transform-origin: left top;
  animation-fill-mode: forwards;
}

@keyframes BorderAppear {
  0% {
    border-color: var(--secondary);
  }
  50% {
    border-color: var(--secondary);
  }
  100% {
    color: var(--bg);
  }
}

.border-appear-animation {
  animation: BorderAppear 1.5s cubic-bezier(0.86, 0, 0.07, 1);
  animation-fill-mode: forwards;
}

@media (max-height: 512px) {
  .small-hide {
    display: none;
  }
}
